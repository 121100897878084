/**
 * Propriété du CISIRH Centre Interministériel de Services Informatiques
 *  relatifs aux Ressources Humaines
 *  41-43 Boulevard Vincent Auriol, 75013 Paris
 *  Édité en 2024
 * 
 *  https://www.economie.gouv.fr/cisirh
 *  contact.cisirh@finances.gouv.fr
 * 
 *  Ce logiciel est un programme informatique servant à assurer le fonctionnement
 *  de l'application Simulateur de Carrière.
 * 
 *  Ce logiciel est régi par la licence CeCILL version 2.1 soumise au droit
 *  français et respectant les principes de diffusion des logiciels libres. Vous
 *  pouvez utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *  de la licence CeCILL version 2.1 telle que diffusée par le CEA, le CNRS et
 *  l'INRIA sur le site "http://www.cecill.info".
 * 
 *  En contrepartie de l'accessibilité au code source et des droits de copie, de
 *  modification et de redistribution accordés par cette licence, il n'est offert
 *  aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons, seule une
 *  responsabilité restreinte pèse sur l'auteur du programme, le titulaire des
 *  droits patrimoniaux et les concédants successifs.
 * 
 *  A cet égard l'attention de l'utilisateur est attirée sur les risques associés
 *  au chargement, à l'utilisation, à la modification et/ou au développement et
 *  à la reproduction du logiciel par l'utilisateur étant donné sa spécificité de
 *  logiciel libre, qui peut le rendre complexe à manipuler et qui le réserve
 *  donc à des développeurs et des professionnels avertis possédant des
 *  connaissances informatiques approfondies. Les utilisateurs sont donc invités
 *  à charger et tester l'adéquation du logiciel à leurs besoins dans des
 *  conditions permettant d'assurer la sécurité de leurs systèmes et ou de leurs
 *  données et, plus généralement, à l'utiliser et l'exploiter dans les mêmes
 *  conditions de sécurité.
 * 
 *  Le fait que vous puissiez accéder à cet en-tête signifie que vous avez pris
 *  connaissance de la licence CeCILL version 2.1, et que vous en avez accepté
 *  les termes.
 */
import {
  INDEMNITE_RESIDENCE_ZONE_1,
  INDEMNITE_RESIDENCE_ZONE_2,
  INDEMNITE_RESIDENCE_ZONE_3,
  MAX_MENSUEL_1E_ENFANT,
  MAX_MENSUEL_2E_ENFANT,
  MAX_MENSUEL_3E_ENFANT,
  MAX_MENSUEL_ENFANT_SUPP,
  MIN_INDEMNITE_RESIDENCE_ZONE_1,
  MIN_INDEMNITE_RESIDENCE_ZONE_2,
  MIN_INDEMNITE_RESIDENCE_ZONE_3,
  MIN_MENSUEL_1E_ENFANT,
  MIN_MENSUEL_2E_ENFANT,
  MIN_MENSUEL_3E_ENFANT,
  MIN_MENSUEL_ENFANT_SUPP,
  PART_FIXE_1E_ENFANT,
  PART_FIXE_2E_ENFANT,
  PART_FIXE_3E_ENFANT,
  PART_FIXE_ENFANT_SUPP,
  POINT_D_INDICE,
  POURCENT_BRUT_1_ENFANT,
  POURCENT_BRUT_2_ENFANT,
  POURCENT_BRUT_3_ENFANT,
  POURCENT_BRUT_ENFANT_SUPP,
} from "../config";
import { detailSalaireType, EchelonsType, VillesType } from "./dataType";
import { horsEchelons } from "./staticData";

// Calcul global (salaire selon l'indice majore, indemnite residence, sft)
export function calculDetailSalaire(
  listeIndices: number[],
  ville: VillesType | undefined,
  nbEnfants: number
): detailSalaireType {
  const detailSalaire: detailSalaireType = {
    listeSalaireTotal: [],
    listeSalaireBase: [],
    listeIndemniteResidence: [],
    listeSupplementFamilial: [],
  };

  for (let i = 0; i < listeIndices.length; i++) {
    detailSalaire.listeSalaireBase.push(listeIndices[i] * POINT_D_INDICE);
    if (typeof ville != "undefined") {
      detailSalaire.listeIndemniteResidence.push(
        calculIndemniteResidence(detailSalaire.listeSalaireBase[i], ville.zone)
      );
    }else {
      detailSalaire.listeIndemniteResidence.push(0);
    }

    detailSalaire.listeSupplementFamilial.push(
      calculSFT(listeIndices[i], nbEnfants)
    );

    detailSalaire.listeSalaireTotal.push(
        detailSalaire.listeSalaireBase[i] +
        (detailSalaire.listeIndemniteResidence[i]) +
        (detailSalaire.listeSupplementFamilial[i] ? detailSalaire.listeSupplementFamilial[i] : 0)
    );
  }

  return detailSalaire;
}

// Calcul de la prime pour les enfants
export function calculSFT(indice_majore: number, nbEnfants: number) {
  const salaireBase = indice_majore * POINT_D_INDICE;
  let total = 0;
  if (nbEnfants === 0) return total;

  // UN ENFANT
  if (nbEnfants === 1) {
    total += PART_FIXE_1E_ENFANT;
    return total;
  }

  // DEUX ENFANT
  if (nbEnfants === 2) {
    let to_add = (POURCENT_BRUT_2_ENFANT * salaireBase) / 100;
    to_add += PART_FIXE_2E_ENFANT;

    if(to_add < MIN_MENSUEL_2E_ENFANT) to_add = MIN_MENSUEL_2E_ENFANT;
    if (to_add > MAX_MENSUEL_2E_ENFANT) to_add = MAX_MENSUEL_2E_ENFANT;

    total += to_add;
    return total;
  }

  // TROISIEME ENFANT
  if (nbEnfants >= 3) {
    let to_add = (POURCENT_BRUT_3_ENFANT * salaireBase) / 100;
    to_add += PART_FIXE_3E_ENFANT;

    if(to_add < MIN_MENSUEL_3E_ENFANT) to_add = MIN_MENSUEL_3E_ENFANT;
    if (to_add > MAX_MENSUEL_3E_ENFANT) to_add = MAX_MENSUEL_3E_ENFANT;

    total += to_add;
  }

  // ENFANT SUPPLEMENTAIRE
  if (nbEnfants >= 4) {
    for (let i = 4; i <= nbEnfants; i++) {
      let to_add = (POURCENT_BRUT_ENFANT_SUPP * salaireBase) / 100;
      to_add += PART_FIXE_ENFANT_SUPP;

      if(to_add < MIN_MENSUEL_ENFANT_SUPP) to_add = MIN_MENSUEL_ENFANT_SUPP;
      if (to_add > MAX_MENSUEL_ENFANT_SUPP) to_add = MAX_MENSUEL_ENFANT_SUPP;
        
      total += to_add;
    }
  }

  return total;
}

// Indemnité de résidence dans la fonction publique
export function calculIndemniteResidence(salaireBase: number, zone: string) {
  let indemnite = 0;
  if (zone === "0") {
    indemnite = INDEMNITE_RESIDENCE_ZONE_1;
  }
  if (zone === "2") {
    indemnite = INDEMNITE_RESIDENCE_ZONE_2;
  }
  if (zone === "3") {
    indemnite = INDEMNITE_RESIDENCE_ZONE_3;
  }
  const res = salaireBase * (indemnite / 100);

  if (
    indemnite === INDEMNITE_RESIDENCE_ZONE_1 &&
    res < MIN_INDEMNITE_RESIDENCE_ZONE_1
  )
    return MIN_INDEMNITE_RESIDENCE_ZONE_1;
  if (
    indemnite === INDEMNITE_RESIDENCE_ZONE_2 &&
    res < MIN_INDEMNITE_RESIDENCE_ZONE_2
  )
    return MIN_INDEMNITE_RESIDENCE_ZONE_2;
  if (
    indemnite === INDEMNITE_RESIDENCE_ZONE_3 &&
    res < MIN_INDEMNITE_RESIDENCE_ZONE_3
  )
    return MIN_INDEMNITE_RESIDENCE_ZONE_3;

  return res;
}

export function getIndiceFromLetter(indice: string): string {
  if (isNaN(Number(indice))) {
    for (let i = 0; i < horsEchelons.length; i++) {
      if (horsEchelons[i].echelon === indice && horsEchelons[i].chevron === 1)
        return String(horsEchelons[i].indice_majore);
    }
  }
  return indice;
}

export function dataToCSV(
  echelons: EchelonsType[],
  detailSalaire: detailSalaireType
): string {

  let result ="\ufeff";
  let libelleLine = "Échelon;";
  let dureeLine = "Durée;";
  let indiceBrutLine = "Indice brut;";
  let indiceMajoreLine = "Indice majoré;";
  let salaireBaseLine = "Salaire de base (brut mensuel);";
  let supplementFamilialLine = "Supplément familial de traitement;";
  let indemniteResidenceLine = "Indemnité de résidence;";
  let salaireTotalLine = "Salaire total (brut mensuel);";

  for (let i = 0; i < echelons.length; i++) {
    libelleLine += echelons[i].libelle + ";";
    dureeLine += (echelons[i].duree === "0000" || echelons[i].duree === "" ? "" :
                    String(Number(echelons[i].duree.slice(0, 2))) + " an"
                 + (Number(echelons[i].duree.slice(0, 2)) <= 1 ? " " : "s")
                 + (Number(echelons[i].duree.slice(2, 4)) === 0 ? "" : 
                          (" - " + String(Number(echelons[i].duree.slice(2, 4))) + " mois"))) + ";";
    indiceBrutLine += Number(echelons[i].indice_brut)+ ";";
    indiceMajoreLine += Number(echelons[i].indice_majore)+ ";";
    salaireBaseLine += Number(detailSalaire.listeSalaireBase[i]).toFixed(2).replace(".", ",") + ";";
    supplementFamilialLine += Number(detailSalaire.listeSupplementFamilial[i]).toFixed(2).replace(".", ",") + ";";
    indemniteResidenceLine += Number(detailSalaire.listeIndemniteResidence[i]).toFixed(2).replace(".", ",") + ";";
    salaireTotalLine += Number(detailSalaire.listeSalaireTotal[i]).toFixed(2).replace(".", ",") + ";";
  }

  result += libelleLine + "\n" + 
          dureeLine + "\n" +
          indiceBrutLine + "\n" +
          indiceMajoreLine + "\n" +
          salaireBaseLine + "\n" +
          supplementFamilialLine + "\n" +
          indemniteResidenceLine + "\n" +
          salaireTotalLine + "\n";

  return result;
}
