/**
 * Propriété du CISIRH Centre Interministériel de Services Informatiques
 *  relatifs aux Ressources Humaines
 *  41-43 Boulevard Vincent Auriol, 75013 Paris
 *  Édité en 2024
 * 
 *  https://www.economie.gouv.fr/cisirh
 *  contact.cisirh@finances.gouv.fr
 * 
 *  Ce logiciel est un programme informatique servant à assurer le fonctionnement
 *  de l'application Simulateur de Carrière.
 * 
 *  Ce logiciel est régi par la licence CeCILL version 2.1 soumise au droit
 *  français et respectant les principes de diffusion des logiciels libres. Vous
 *  pouvez utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *  de la licence CeCILL version 2.1 telle que diffusée par le CEA, le CNRS et
 *  l'INRIA sur le site "http://www.cecill.info".
 * 
 *  En contrepartie de l'accessibilité au code source et des droits de copie, de
 *  modification et de redistribution accordés par cette licence, il n'est offert
 *  aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons, seule une
 *  responsabilité restreinte pèse sur l'auteur du programme, le titulaire des
 *  droits patrimoniaux et les concédants successifs.
 * 
 *  A cet égard l'attention de l'utilisateur est attirée sur les risques associés
 *  au chargement, à l'utilisation, à la modification et/ou au développement et
 *  à la reproduction du logiciel par l'utilisateur étant donné sa spécificité de
 *  logiciel libre, qui peut le rendre complexe à manipuler et qui le réserve
 *  donc à des développeurs et des professionnels avertis possédant des
 *  connaissances informatiques approfondies. Les utilisateurs sont donc invités
 *  à charger et tester l'adéquation du logiciel à leurs besoins dans des
 *  conditions permettant d'assurer la sécurité de leurs systèmes et ou de leurs
 *  données et, plus généralement, à l'utiliser et l'exploiter dans les mêmes
 *  conditions de sécurité.
 * 
 *  Le fait que vous puissiez accéder à cet en-tête signifie que vous avez pris
 *  connaissance de la licence CeCILL version 2.1, et que vous en avez accepté
 *  les termes.
 */
export const API_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://localhost:4444/api/simulateur-carriere";

export const POINT_D_INDICE = 4.92278;
// INDEMNITE RESIDENCE
// https://www.service-public.fr/particuliers/vosdroits/F32511
export const MIN_INDEMNITE_RESIDENCE_ZONE_1 = 54.02;
export const MIN_INDEMNITE_RESIDENCE_ZONE_2 = 18;
export const MIN_INDEMNITE_RESIDENCE_ZONE_3 = 0;
export const INDEMNITE_RESIDENCE_ZONE_1 = 3
export const INDEMNITE_RESIDENCE_ZONE_2 = 1
export const INDEMNITE_RESIDENCE_ZONE_3 = 0

// SUPPLEMENT FAMILIAL
// https://www.service-public.fr/particuliers/vosdroits/F32513
export const PART_FIXE_1E_ENFANT = 2.29;
export const PART_FIXE_2E_ENFANT = 10.67;
export const PART_FIXE_3E_ENFANT = 15.24;
export const PART_FIXE_ENFANT_SUPP = 4.57;

export const POURCENT_BRUT_1_ENFANT = 0;
export const POURCENT_BRUT_2_ENFANT = 3;
export const POURCENT_BRUT_3_ENFANT = 8;
export const POURCENT_BRUT_ENFANT_SUPP = 6;

export const MIN_MENSUEL_1E_ENFANT = 2.29;
export const MIN_MENSUEL_2E_ENFANT = 77.71;
export const MIN_MENSUEL_3E_ENFANT = 194.03;
export const MIN_MENSUEL_ENFANT_SUPP = 138.66;

export const MAX_MENSUEL_1E_ENFANT = 2.29;
export const MAX_MENSUEL_2E_ENFANT = 117.29;
export const MAX_MENSUEL_3E_ENFANT = 299.57;
export const MAX_MENSUEL_ENFANT_SUPP = 217.82;

// COULEUR BARCHART
export const COULEUR_SALAIRE = '#223b89';
export const COULEUR_INDEMNITE_RESIDENCE = '#d87d0f';
export const COULEUR_SFT = '#e20e21';

// Evolution du carrière (arbre) couleur avec la syntaxe de tailwind
export const TREE_MAX_DEPTH = 3;
export const COULEUR_GRADE_ACTUELLE = 'bg-blue-100';
export const COULEUR_GRADE_SUIVANT = 'bg-gray-100';

