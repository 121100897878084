/**
 * Propriété du CISIRH Centre Interministériel de Services Informatiques
 *  relatifs aux Ressources Humaines
 *  41-43 Boulevard Vincent Auriol, 75013 Paris
 *  Édité en 2024
 * 
 *  https://www.economie.gouv.fr/cisirh
 *  contact.cisirh@finances.gouv.fr
 * 
 *  Ce logiciel est un programme informatique servant à assurer le fonctionnement
 *  de l'application Simulateur de Carrière.
 * 
 *  Ce logiciel est régi par la licence CeCILL version 2.1 soumise au droit
 *  français et respectant les principes de diffusion des logiciels libres. Vous
 *  pouvez utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *  de la licence CeCILL version 2.1 telle que diffusée par le CEA, le CNRS et
 *  l'INRIA sur le site "http://www.cecill.info".
 * 
 *  En contrepartie de l'accessibilité au code source et des droits de copie, de
 *  modification et de redistribution accordés par cette licence, il n'est offert
 *  aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons, seule une
 *  responsabilité restreinte pèse sur l'auteur du programme, le titulaire des
 *  droits patrimoniaux et les concédants successifs.
 * 
 *  A cet égard l'attention de l'utilisateur est attirée sur les risques associés
 *  au chargement, à l'utilisation, à la modification et/ou au développement et
 *  à la reproduction du logiciel par l'utilisateur étant donné sa spécificité de
 *  logiciel libre, qui peut le rendre complexe à manipuler et qui le réserve
 *  donc à des développeurs et des professionnels avertis possédant des
 *  connaissances informatiques approfondies. Les utilisateurs sont donc invités
 *  à charger et tester l'adéquation du logiciel à leurs besoins dans des
 *  conditions permettant d'assurer la sécurité de leurs systèmes et ou de leurs
 *  données et, plus généralement, à l'utiliser et l'exploiter dans les mêmes
 *  conditions de sécurité.
 * 
 *  Le fait que vous puissiez accéder à cet en-tête signifie que vous avez pris
 *  connaissance de la licence CeCILL version 2.1, et que vous en avez accepté
 *  les termes.
 */
import React from "react";

const Footer = () => {
  return (
    <footer className="flex justify-center items-center bg-gray-200">
      <div className="flex footer justify-center items-center">
        <div className="flex footer items-center ml-20">
          
          <p className="mr-2 text-xl text-gray-500 font-sans whitespace-nowrap">Développé par le</p>
          
          <a href="https://www.economie.gouv.fr/cisirh">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo_cisirh.png`}
              alt="Acceuil"
              className="img_logo w-18 h-14"
            />
          </a>

          <a href="https://www.fonction-publique.gouv.fr/la-dgafp">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo_ministere_transformation.png`}
              alt="Acceuil"
              className="img_logo w-50 h-24 ml-4"
            />
          </a>
        </div>

        <a href="https://nexus.cisirh.rie.gouv.fr/repository/RGAA/CISIRH/d%C3%A9claration%20d'accessibilit%C3%A9%20du%20Simulateur%20de%20carri%C3%A8re" 
          target="_blank" className="w-full mx-20 text-xl text-blue-500 hover:underline" rel="noreferrer">
          Accessibilité : non conforme.
        </a>
      </div>
      
    </footer>
  );
};

export { Footer };