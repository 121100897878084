/**
 * Propriété du CISIRH Centre Interministériel de Services Informatiques
 *  relatifs aux Ressources Humaines
 *  41-43 Boulevard Vincent Auriol, 75013 Paris
 *  Édité en 2024
 * 
 *  https://www.economie.gouv.fr/cisirh
 *  contact.cisirh@finances.gouv.fr
 * 
 *  Ce logiciel est un programme informatique servant à assurer le fonctionnement
 *  de l'application Simulateur de Carrière.
 * 
 *  Ce logiciel est régi par la licence CeCILL version 2.1 soumise au droit
 *  français et respectant les principes de diffusion des logiciels libres. Vous
 *  pouvez utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *  de la licence CeCILL version 2.1 telle que diffusée par le CEA, le CNRS et
 *  l'INRIA sur le site "http://www.cecill.info".
 * 
 *  En contrepartie de l'accessibilité au code source et des droits de copie, de
 *  modification et de redistribution accordés par cette licence, il n'est offert
 *  aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons, seule une
 *  responsabilité restreinte pèse sur l'auteur du programme, le titulaire des
 *  droits patrimoniaux et les concédants successifs.
 * 
 *  A cet égard l'attention de l'utilisateur est attirée sur les risques associés
 *  au chargement, à l'utilisation, à la modification et/ou au développement et
 *  à la reproduction du logiciel par l'utilisateur étant donné sa spécificité de
 *  logiciel libre, qui peut le rendre complexe à manipuler et qui le réserve
 *  donc à des développeurs et des professionnels avertis possédant des
 *  connaissances informatiques approfondies. Les utilisateurs sont donc invités
 *  à charger et tester l'adéquation du logiciel à leurs besoins dans des
 *  conditions permettant d'assurer la sécurité de leurs systèmes et ou de leurs
 *  données et, plus généralement, à l'utiliser et l'exploiter dans les mêmes
 *  conditions de sécurité.
 * 
 *  Le fait que vous puissiez accéder à cet en-tête signifie que vous avez pris
 *  connaissance de la licence CeCILL version 2.1, et que vous en avez accepté
 *  les termes.
 */
import React, {useCallback, useEffect, useState} from "react";
import { tap } from "rxjs/operators"
import { useQuery } from "react-query";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  Button,
  TextField, Autocomplete,
} from "@mui/material";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { SimCar } from "./components/SimCar";
import API from "./services/api";
import {
  CorpsType,
  DepartementsType,
  EchelonsType,
  GradesType,
  MinisteresType,
  ToliniDataType,
  ToliniType,
  VillesType
} from "./services/dataType";
import { Link, Navigate, useNavigate, Route, Routes } from "react-router-dom";
import { EvolutionCarriere } from "./components/EvolutionCarriere";
import MinisteresService from "./services/ministeres.service";
import departementsService from "./services/departements.service";
import gradesService from "./services/grades.service";
import echelonsService from "./services/echelons.service";
import villesService from "./services/villes.service";
import { toliniService } from "./services/tolini.service";
import CorpsService from "./services/corps.service";
import Version from "./components/Version";

const PaperProps = {
  sx: {
    height: "50%",
    bgcolor: "white",
    "& .MuiMenuItem-root": {
      padding: 2,
    },
  },
}

function App() {
  // FORM STATE
  const navigate = useNavigate();

  const [ministeres, setMinisteres] = useState<MinisteresType[]>([]);
  const [corps, setCorps] = useState<CorpsType[]>([]);

  const [departements, setDepartements] = useState<DepartementsType[]>([]);

  const [grades, setGrades] = useState<GradesType[]>([]);

  const [echelons, setEchelons] = useState<EchelonsType[]>([]);

  const [villes, setVilles] = useState<VillesType[]>([]);

  const [isTolini, setIsTolini] = useState<boolean>();

  const [choixGrades, setChoixGrades] = useState("");
  const [choixCorps, setChoixCorps] = useState("");
  const [choixMinisteres, setChoixMinisteres] = useState("");
  const [choixEchelons, setChoixEchelons] = useState("");
  const [choixEchelonsParcours, setChoixEchelonsParcours] = useState("");
  const [choixDepartements, setChoixDepartements] = useState("");
  const [choixVilles, setChoixVilles] = useState("");
  const [nbEnfants, setNbEnfants] = useState(0);
  const [currGrade, setCurrGrade] = useState<GradesType | undefined>(null);
  const [currMinistere, setCurrMinistere] = useState<MinisteresType | undefined>(null);
  const [currCorps, setCurrCorps] = useState<CorpsType | undefined>(null);

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<GradesType | undefined>(null);

  const [inputValueMinistere, setInputValueMinistere] = useState('');
  const [valueMinistere, setValueMinistere] = useState<MinisteresType | undefined>(null);

  const [inputValueCorps, setInputValueCorps] = useState('');
  const [valueCorps, setValueCorps] = useState<CorpsType | undefined>(null);
  useEffect(() => {
    const subscription = CorpsService.getCorps()
        .pipe(tap(setCorps))
        .subscribe();
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = MinisteresService.getMinisteres()
      .pipe(tap(setMinisteres))
      .subscribe();
    return () => subscription.unsubscribe();
  }, []);


  useEffect(() => {
    const subscription = departementsService.getDepartements()
      .pipe(tap(setDepartements))
      .subscribe();
    return () => subscription.unsubscribe();
  }, []);


  useEffect(() => {
      if(choixMinisteres){
        const subscription = gradesService.getGrades(choixMinisteres)
        .pipe(tap(setGrades))
        .subscribe();
        return () => subscription.unsubscribe();
      }

    if(choixCorps){
      const subscription = gradesService.getGradesCorps(choixCorps)
          .pipe(tap(setGrades))
          .subscribe();
      return () => subscription.unsubscribe();
    }
  }, [choixMinisteres, choixCorps]);

  useEffect(() => {
    if(choixGrades){
      const subscription = echelonsService.getEchelons(choixGrades)
          .pipe(tap(echelons => {
            const sortedEchelons = echelons.sort((a, b) => +a.rang - +b.rang);
            setEchelons(sortedEchelons);

            if (sortedEchelons.length > 0) {
              setChoixEchelonsParcours(sortedEchelons[0].code_echelon); // Set it to the first element
            }
          }))
          .subscribe();
          return () => subscription.unsubscribe();
    }
  }, [choixGrades]);

/*  useEffect(() => {
    if(choixGrades){
      const subscription = echelonsService.getEchelons(choixGrades)
      .pipe(tap(echelons => setEchelons(echelons.sort((a, b) => +a.rang - +b.rang))))
      .subscribe();
    return () => subscription.unsubscribe();
    }
  }, [choixGrades]);*/

  useEffect(() => {
    if(choixDepartements){
      const subscription = villesService.getVilles(choixDepartements)
      .pipe(tap(setVilles))
      .subscribe();
    return () => subscription.unsubscribe();
    }
  }, [choixDepartements]);


  useEffect(() => {
    if(choixGrades){
      const subscription = toliniService.getTolini(choixGrades)
      .pipe(tap(setIsTolini))
      .subscribe();
    return () => subscription.unsubscribe();
    }
  }, [choixGrades]);

  const getRangEchelon = () => {
    for (let i = 0; i < echelons.length; i++) {
      if (choixEchelons === echelons[i].code_echelon) return i;
    }
  };

  const getRangEchelonParcours = () => {
    for (let i = 0; i < echelons.length; i++) {
      if (choixEchelonsParcours === echelons[i].code_echelon) return i;
    }
  };

  const changeMinisteres = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;

    const newMinisteres =
      ministeres &&
      ministeres.find((m) => m.code_ministere === eventValue);

    // obtention des grades(postes) associés au ministere
    if (newMinisteres) {
      setChoixMinisteres(newMinisteres.code_ministere);
      setChoixGrades("");
      setChoixEchelons("");
    }
  };

  const changeGrades = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;

    const newGrade =
      grades && grades.find((g) => g.code_grade === eventValue);
    if (newGrade) {
      setCurrGrade(newGrade);
      setChoixGrades(newGrade.code_grade);
      setChoixEchelons("");
    }
  };

  const changeEchelons = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;
    const newEchelon =
      echelons &&
      echelons.find((e) => e.code_echelon === eventValue);
    if (newEchelon) {
      setChoixEchelons(newEchelon.code_echelon);
      setChoixEchelonsParcours(echelons[0].code_echelon)
    }
  };

  const changeDepartements = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;

    const newDepartements =
      departements &&
      departements.find((d) => d.num_departement === eventValue);

    if (newDepartements) {
      setChoixDepartements(newDepartements.num_departement);
    }
  };

  const changeVilles = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;

    const newVille =
      villes && villes.find((v) => v.ville === eventValue);

    if (newVille) {
      setChoixVilles(newVille.ville);
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const handleLaunchSimulator = () => {
    const simCarData = {
      echelons,
      choixEchelons: choixEchelonsParcours,
      userGrade: grades.find(g => choixGrades === g.code_grade),
      userVille: villes.find(v => choixVilles === v.ville),
      nbEnfants,
      toliniExist: true
    };
  
    localStorage.setItem('simCarData', JSON.stringify(simCarData));
    navigate("/simulateur-carriere/chart", { replace: false });
  };

  return (
    <div className="App flex flex-col justify-between w-screen overflow-x-hidden">
      <Header />

      <div className="content h-full">
        <Routes>
          <Route path="/" element={<Navigate to="simulateur-carriere" />} />
          <Route
            path="/simulateur-carriere"
            element={
              <div className="flex justify-center items-center content-wrapper h-full w-full bg-grey">
                <div className="content flex justify-center items-center w-2/3 m-2 p-2">
                  <div className="flex-col justify-center items-center w-screen border-4 rounded-xl p-2²">
                    <h1 className="text-3xl p-4 text-center">
                      Précisez votre situation
                    </h1>
                    <form
                      onSubmit={handleFormSubmit}
                      className="flex flex-col justify-center items-center gap-3"
                    >
                      <div style={{width: "100%",
                        display: "flex",
                        justifyContent: "center"}}>
                        <FormControl className="w-96">
                          <Autocomplete
                              value={valueMinistere}
                              onChange={(event: any, newValue: MinisteresType | null) => {
                                setCurrMinistere(newValue);
                                setValueMinistere(newValue)
                                setChoixMinisteres(newValue ? newValue.code_ministere : null);
                                setChoixGrades("");
                                setCurrGrade(undefined);
                                setValue(undefined);
                                setChoixEchelons("");
                                if (choixCorps) {
                                  setValueCorps(undefined)
                                  setChoixCorps("");
                                  setCurrCorps(undefined);

                                  setValue(undefined);
                                  setChoixGrades("");
                                  setCurrGrade(undefined);

                                  setChoixEchelons("");
                                }

                              }}
                              defaultValue={currMinistere}
                              inputValue={inputValueMinistere}
                              onInputChange={(event, newInputValue) => {
                                setInputValueMinistere(newInputValue);
                              }}
                              className="my-2 py-2 w-full"
                              id="manageable-states-demo"
                              options={(ministeres || []).map((ministere) => ({
                                ...ministere,
                                key: ministere.code_ministere
                              }))}
                              renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.code_ministere}>
                                      {option.libelle}
                                    </li>
                                );
                              }}
                              sx={{
                                "& .MuiInputBase-root": { height: "72px" },
                              }}
                              getOptionLabel={(option: MinisteresType) => option.libelle}
                              isOptionEqualToValue={useCallback((option, value) => {
                                if (option && value && option.code_ministere && value.code_ministere) {
                                  return option.code_ministere === value.code_ministere;
                                }
                                return false;
                              }, [])}
                              renderInput={(params) => <TextField {...params} label="Sélectionner votre ministère *" />}
                          />
                        </FormControl>
                        <div className="m-8"><p>Ou</p></div>
                        <FormControl className="w-96">
                          <Autocomplete
                              value={valueCorps}
                              onChange={(event: any, newValue: CorpsType | null) => {
                                setCurrCorps(newValue);
                                setValueCorps(newValue)
                                setChoixCorps(newValue ? newValue.code_corps : null);
                                setValue(undefined);
                                setChoixGrades("");
                                setCurrGrade(undefined);
                                setChoixEchelons("");
                                if (choixMinisteres) {
                                  setValueMinistere(undefined)
                                  setChoixMinisteres("");
                                  setCurrMinistere(undefined);

                                  setValue(undefined);
                                  setChoixGrades("");
                                  setCurrGrade(undefined);

                                  setChoixEchelons("");
                                }
                              }}
                              defaultValue={currCorps}
                              inputValue={inputValueCorps}
                              onInputChange={(event, newInputValue) => {
                                setInputValueCorps(newInputValue);
                              }}
                              clearOnBlur
                              className="my-2 py-2 w-full"
                              id="manageable-states-demo"
                              options={(corps || []).map((corp) => ({
                                ...corp,
                                key: corp.code_corps
                              }))}
                              renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.code_corps}>
                                      {option.libelle}
                                    </li>
                                );
                              }}
                              sx={{
                                "& .MuiInputBase-root": { height: "72px" },
                              }}
                              getOptionLabel={(option: CorpsType) => option.libelle}
                              isOptionEqualToValue={useCallback((option, value) => {
                                if (option && value && option.code_corps && value.code_corps) {
                                  return option.code_corps === value.code_corps;
                                }
                                return false;
                              }, [])}
                              renderInput={(params) => <TextField {...params}  label="Sélectionner votre corps *" />}
                          />
                        </FormControl>
                      </div>


                      <FormControl className="w-96">
                        <Autocomplete
                            value={value}
                            onChange={(event: any, newValue: GradesType | null) => {
                              setCurrGrade(newValue);
                              setValue(newValue);
                              setChoixGrades(newValue? newValue.code_grade : null);
                              setChoixEchelons("");
                            }}
                            defaultValue={currGrade}
                            disabled={choixMinisteres === "" && choixCorps === ""}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            className="my-2 py-2 w-full"
                            id="manageable-states-demo"
                            options={(grades || []).map((grade) => ({
                              ...grade,
                              key: grade.code_grade
                            }))}
                            renderOption={(props, option) => {
                              return (
                                  <li {...props} key={option.code_grade}>
                                    {option.libelle}
                                  </li>
                              );
                            }}
                            clearOnBlur
                            sx={{
                              "& .MuiInputBase-root": { height: "72px" },
                            }}
                            getOptionLabel={(option: GradesType) => option.libelle}
                            isOptionEqualToValue={useCallback((option, value) => {
                              if (option && value && option.code_grade && value.code_grade) {
                                return option.code_grade === value.code_grade;
                              }
                              return false;
                            }, [])}
                            renderInput={(params) => <TextField {...params} label="Sélectionner votre grade *" />}
                        />
                      </FormControl>
                      <FormControl className="w-96">
                        <InputLabel id="select-echelons">
                          Sélectionner votre échelon
                        </InputLabel>
                        <Select
                          name="echelons"
                          labelId="select-echelons"
                          onChange={changeEchelons}
                          className="my-2 py-2 w-full"
                          value={choixEchelons}
                          disabled={choixGrades === ""}
                        >
                          {echelons &&
                            echelons.map((e, index) => (
                              <MenuItem value={e.code_echelon} key={index}>
                                {e.libelle}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl className="w-96">
                        <InputLabel id="select-departements">
                          Sélectionner votre département
                        </InputLabel>
                        <Select
                          name="departements"
                          labelId="select-departements"
                          onChange={changeDepartements}
                          className="my-2 py-2 w-full"
                          value={choixDepartements}
                        >
                          {departements &&
                            departements.map((d, index) => (
                              <MenuItem value={d.num_departement} key={index}>
                                {d.num_departement} - {d.libelle}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl className="w-96">
                        <InputLabel id="select-villes">
                          Sélectionner votre ville
                        </InputLabel>
                        <Select
                          name="villes"
                          labelId="select-villes"
                          onChange={changeVilles}
                          className="my-2 py-2 w-full"
                          value={choixVilles}
                          disabled={choixDepartements === ""}
                        >
                          {villes &&
                            villes.map((v, index) => (
                              <MenuItem
                                value={v.ville}
                                key={index}
                                defaultValue=""
                              >
                                {v.ville}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl className="w-96">
                        <TextField
                          onChange={(e) => setNbEnfants(Number(e.target.value))}
                          className="my-2 py-2"
                          value={nbEnfants}
                          type="number"
                          inputProps={{ min: 0}}
                          label="Nombre d'enfant(s) à charge"
                          id="input-nbEnfants"
                        />
                      </FormControl>
                      <FormControl className="flex justify-center items-center">
                          <div className="m-4">
                            <Button style={{textTransform: 'none'}} type="submit" variant="contained"
                                    disabled={(!choixCorps && !choixGrades) || (!choixMinisteres && !choixGrades) || (!choixMinisteres && !choixCorps)}
                                    onClick={handleLaunchSimulator}>
                              Lancer le simulateur de carrière
                            </Button>
                          </div>
                      </FormControl>
                    </form>
                  </div>
                </div>
              </div>
            }
          />

          {/* Chart Simulateur de Carrière */}
          <Route
            path="simulateur-carriere/chart"
            element={<SimCar/>}
          />

          {true && (
            <Route
              path="simulateur-carriere/evolution-carriere"
              element={
                echelons && (<EvolutionCarriere/>)
              }
            ></Route>
          )}
          <Route
          path='simulateur-carriere/version'
          element={<Version/>}
          ></Route>
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
