/**
 * Propriété du CISIRH Centre Interministériel de Services Informatiques
 *  relatifs aux Ressources Humaines
 *  41-43 Boulevard Vincent Auriol, 75013 Paris
 *  Édité en 2024
 * 
 *  https://www.economie.gouv.fr/cisirh
 *  contact.cisirh@finances.gouv.fr
 * 
 *  Ce logiciel est un programme informatique servant à assurer le fonctionnement
 *  de l'application Simulateur de Carrière.
 * 
 *  Ce logiciel est régi par la licence CeCILL version 2.1 soumise au droit
 *  français et respectant les principes de diffusion des logiciels libres. Vous
 *  pouvez utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *  de la licence CeCILL version 2.1 telle que diffusée par le CEA, le CNRS et
 *  l'INRIA sur le site "http://www.cecill.info".
 * 
 *  En contrepartie de l'accessibilité au code source et des droits de copie, de
 *  modification et de redistribution accordés par cette licence, il n'est offert
 *  aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons, seule une
 *  responsabilité restreinte pèse sur l'auteur du programme, le titulaire des
 *  droits patrimoniaux et les concédants successifs.
 * 
 *  A cet égard l'attention de l'utilisateur est attirée sur les risques associés
 *  au chargement, à l'utilisation, à la modification et/ou au développement et
 *  à la reproduction du logiciel par l'utilisateur étant donné sa spécificité de
 *  logiciel libre, qui peut le rendre complexe à manipuler et qui le réserve
 *  donc à des développeurs et des professionnels avertis possédant des
 *  connaissances informatiques approfondies. Les utilisateurs sont donc invités
 *  à charger et tester l'adéquation du logiciel à leurs besoins dans des
 *  conditions permettant d'assurer la sécurité de leurs systèmes et ou de leurs
 *  données et, plus généralement, à l'utiliser et l'exploiter dans les mêmes
 *  conditions de sécurité.
 * 
 *  Le fait que vous puissiez accéder à cet en-tête signifie que vous avez pris
 *  connaissance de la licence CeCILL version 2.1, et que vous en avez accepté
 *  les termes.
 */
import axios, { AxiosInstance } from "axios";
import { Observable, defer } from "rxjs";
import { map } from 'rxjs/operators';
import { API_URL } from "../config";
import {
  EchelonsType,
  GradesType,
  MinisteresType,
  VillesType,
  DepartementsType,
  isToliniType,
} from "./dataType";

import { getIndiceFromLetter } from "./utils";
class API_generator {
  ApiInstance: AxiosInstance;

  constructor() {
    this.ApiInstance = axios.create({
      baseURL: API_URL,
    });
  }

  getAxiosInstance() {
    return this.ApiInstance
  }

  get = <T>(url: string, queryParams?: object): Observable<T> => {
    return defer(() => this.ApiInstance.get<T>(url, { params: queryParams }))
      .pipe(map(result => result.data));
  };

  post = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
    return defer(() => this.ApiInstance.post<T>(url, body, { params: queryParams }))
      .pipe(map(result => result.data));
  };

  put = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
    return defer(() => this.ApiInstance.put<T>(url, body, { params: queryParams }))
      .pipe(map(result => result.data));
  };

  patch = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
    return defer(() => this.ApiInstance.patch<T>(url, body, { params: queryParams }))
      .pipe(map(result => result.data));
  };

  deleteR = <T>(url: string, id: number): Observable<T | void> => {
    return defer(() => (this.ApiInstance.delete(`${url}/${id}`)))
      .pipe(map(result => result.data)
      );
  };

  // getGrades(code_ministere: string) {
  //   return this.ApiInstance.get<GradesType[]>(`/grades/${code_ministere}`).then(
  //     (res) => {
  //       // console.log(res.data)
  //       return res.data.sort((a, b) => (a.libelle > b.libelle) ? 1 : -1)
  //     }
  //   );
  // }

  // getEchelons(code_grade: string) {
  //   return this.ApiInstance.get<EchelonsType[]>(`/echelons/${code_grade}`).then(
  //     (res) => {
  //       let result = res.data;
  //       // cas indice majore hors echelon
  //       result.forEach((r) => {
  //         r.indice_majore = getIndiceFromLetter(r.indice_majore);
  //       });
  //       result = result.sort(
  //         (a, b) => Number(a.indice_majore) - Number(b.indice_majore)
  //       );
  //       return result;
  //     }
  //   );
  // }

  // getDepartements() {
  //   return this.ApiInstance.get<DepartementsType[]>("/departements").then(
  //     (res) => {
  //       return res.data;
  //     }
  //   );
  // }

  // getVilles(idDepartement: string) {
  //   return this.ApiInstance.get<VillesType[]>(`/villes/${idDepartement}`).then(
  //     (res) => {
  //       // console.log(res.data)
  //       return res.data;
  //     }
  //   );
  // }

  // getTolini(idGrades: string) {
  //   return this.ApiInstance.get<isToliniType>(`/tolini/exist/${idGrades}`).then(
  //     (res) => {
  //       let result = res.data;
  //       return result.isTolini;
  //     }
  //   );
  // }
}

export default new API_generator();
